<template>
  <div :class="getDark ? 'bg-gelap' : 'bg-terang'" class="relative">
    <app-nav v-if="displayMenu">
    </app-nav>
    <app-menu v-if="displayMenu">
    </app-menu>
    <div class="w-full z-20">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
      <a @click="scrollTop" v-show="visible" class="fixed bottom-10 right-5">
        <i :class="getDark ? 'text-orange-500':'text-primary'"
          class="fa-solid fa-circle-arrow-up w-8 h-8 hover:cursor-pointer"></i>
      </a>
    </div>
    <app-footer v-if="displayMenu" class="z-50"></app-footer>
  </div>
  <div>
    <vue-final-modal v-model="showPayment.show" content-class="modal-content" classes="modal-container"
      class="relative">
      <div class="modal__close" @click="closeIframe">
        <i class="fa-solid fa-circle-xmark text-red-500 bg-white rounded-full text-2xl"></i>
      </div>
      <span class="modal__title"></span>
      <div class="modal__content">
        <iframe :src="showPayment.link" style="position: relative; height: 100%; width: 100%;" frameborder="0"></iframe>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import Menu from './views/Menu.vue';
import { mapGetters } from 'vuex';
import AppNavBar from './views/NavBar.vue';
import Footer from './views/Footer.vue';
import Mixin from '@/mixin/index'
import { VueFinalModal, ModalsContainer } from 'vue-final-modal'

export default {
  components: {
    VueFinalModal,
    ModalsContainer,
    AppNav: AppNavBar,
    AppFooter: Footer,
    AppMenu: Menu,
  },
  mixins: [Mixin],
  data() {
    return {
      visible: false,
      showIframe: true,
    }
  },
  methods: {
    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener: function (e) {
      this.visible = window.scrollY > 150;
    },
    closeIframe() {
      this.$store.dispatch('showPayment', null)
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  created() {
    var params = {
      limit: 8,
      skip: 0,
      filter_product_name: '',
      filter_tahun: '',
      filter_category: '',
      sort: 'tahun DESC',
    };
    this.$store.dispatch('modProducts/setAllProducts', params);
    this.$store.dispatch('modKategori/setCategory');
    this.$store.dispatch('modContent/setTitleHome');
    this.$store.dispatch('modContent/setSubTitleHome');
    this.$store.dispatch('modContent/setContentHome');
    this.$store.dispatch('modContent/setFooterContent');
    this.$store.dispatch('modContent/setPhoneCompany');
    this.$store.dispatch('modContent/setEmailCompany');
    this.$store.dispatch('fetchLogo');
    this.$store.dispatch('fetchBanner');


    const stoMenuDisplay = JSON.parse(localStorage.getItem('menuDisplay'));
    this.$store.dispatch('setDisplayMenu', stoMenuDisplay);
  },
  computed: {
    ...mapGetters({
      getAuth: 'getAuth',
      getShowAlert: 'getShowAlert',
      getAllProducts: 'modProducts/getAllProducts',
      getCompleted: 'modTransaksi/getCompleted',
      getWaiting: 'modTransaksi/getWaiting',
      getCategory: 'modKategori/getCategory',
      getDark: 'getDark',
      showPayment: 'showPayment',
      displayMenu: 'displayMenu',
    }),
  },
};
</script>
<style scoped>
.bg-terang {
  background-color: rgb(255, 255, 255);
}

.bg-gelap {
  background-color: rgb(26, 26, 26);
}

*,
body,
html {
  background-color: transparent;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}



::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}

.modal__close {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  cursor: pointer;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>