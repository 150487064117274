export default {
  methods: {
    showLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 800);
    },
    checkEmail(val) {
      var filter = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!filter.test(val)) {
        return false;
      }
      return true;
    },
    limitStr(string, limit) {
      let str = string;
      if (typeof str === "string" && str.length > limit) {
        str = str.slice(0, limit) + " ...";
      }
      return str;
    },
    tampilAlert(warning) {
      setTimeout(() => {
        this.$store.dispatch("setShowAlert", warning);
      }, 1000);
      setTimeout(() => {
        this.loadingSpinner = false;
        this.$store.dispatch("setShowAlert", warning);
      }, 2000);
    },
    filterUpperCase(value) {
      return value ? value.toUpperCase() : "";
    },
    showAlert(type, msg) {
      this.$swal({
        toast: true,
        icon: type,
        title: msg,
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          // toast.addEventListener('mouseenter', swal.stopTimer)
          // toast.addEventListener('mouseleave', swal.resumeTimer)
        },
      });
    },
    loader() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
        color: "#fff",
        loader: "spinner",
        width: 64,
        height: 64,
        backgroundColor: "#000",
        opacity: 1,
      });
      // simulate AJAX
      setTimeout(() => {
        loader.hide();
      }, 1000);
    },
    viewProduct(id) {
      this.loader();
      this.$store.dispatch("modProducts/setDetailProduct", id);
      this.$router.push({ name: "productDetail", params: { id: id } });
    },
    formatRupiah(val) {
      const format = (val + "")
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      const rupiah = "Rp " + format;
      return rupiah;
    },
  },
};