<template>
  <div class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <section class="text-gray-600 body-font relative">
          <div class="container py-10 mx-auto flex sm:flex-nowrap flex-wrap">
            <!-- map  lg:w-2/3 md:w-1/2-->
            <div class="hidden md:flex w-full h-[450px] bg-gray-300 rounded-lg overflow-hidden items-end justify-start relative">
              <!-- <iframe width="100%" height="100%" class="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%C4%B0zmir+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" style="filter: grayscale(1) contrast(1.2) opacity(0.4);"></iframe> -->

              <iframe width="100%" height="100%" class="inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15864.781400289432!2d106.7829656!3d-6.2379636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f13d4bc00243%3A0xab8aa41d02d005a2!2sMajalah%20Infobank!5e0!3m2!1sen!2sid!4v1655562328764!5m2!1sen!2sid"  style="filter: grayscale(0.8) opacity(0.7);"></iframe>



              <div class="bg-white absolute w-[97%] lg:w-1/2 flex top-2 right-2 left-2 p-6 rounded shadow-md">
                <div class="lg:w-1/2 px-6">
                  <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                  <p class="mt-1">{{ company.address }}</p>
                </div>
                <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                  <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                  <a class="text-indigo-500 leading-relaxed">{{ company.email }}</a>
                  <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                  <p class="leading-relaxed">{{ company.phone }}</p>
                </div>
              </div> 


            </div>
            <!-- form  -->
            <!-- <div class="lg:w-1/3 md:w-full flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 lg:pt-0">
              <h2
              :class="getDark ? 'text-zinc-200':'text-gray-900'"
              class="text-lg mb-1 font-medium title-font">Feedback</h2>
              <p :class="getDark ? 'text-zinc-400':'text-gray-600'"
              class="leading-relaxed mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias sed veritatis aliquam praesentium autem, cum consequuntur facere! Vero, perspiciatis sapiente.</p>
              
              <form ref="form" @submit.prevent="sendEmail">
              <div class="relative mb-4">
                <label
                :class="getDark ? 'text-zinc-400':'text-gray-600'"
                for="name" class="leading-7 text-sm">Name</label>
                <input type="text" id="name" v-model="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
              </div>
              <div class="relative mb-4">
                <label
                :class="getDark ? 'text-zinc-400':'text-gray-600'"
                for="email" class="leading-7 text-sm">Email</label>
                <input type="email" id="email" v-model="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
              </div>
              <div class="relative mb-4">
                <label
                :class="getDark ? 'text-zinc-400':'text-gray-600'"
                for="message" class="leading-7 text-sm">Message</label>
                <textarea id="message" v-model="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
              </div>
              <div class="flex px-4 sm:px-0 hover:cursor-pointer">
                <button class="flex items-center justify-center h-12 px-4 rounded-md bg-primary text-white ease-in-out duration-300" :disabled="isSend">
                  <svg v-if="isSend" class="w-5 h-5 text-white animate-spin left-1/2 mr-2" fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor" />
                  </svg> 
                  <span class="font-bold">{{ isSend ? 'Loading ...':'Send Email' }}</span>
                </button>
              </div>
              
              </form>
            </div> -->
          </div>
        </section>
    </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import emailjs from '@emailjs/browser';
	import Mixin from '@/mixin';

  export default {
    name:"ContactUs",
    mixins: [Mixin],
    data() {
      return {
        isSend: false,
        timeout: false,
        name: '',
        email: '',
        message: '',
        publicKey: 'EmBJLFlpwpElDKteS',
        serviceId: 'service_notldjk',
        templateId: 'template_3di3xwd',
      }
    },
    methods: {
      sendEmail() {
        // console.log('asd')
        if (this.name != '' && this.email != '' && this.message != '') {
          const email = this.checkEmail(this.email);
          if (email) {
            this.isSend = true;
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
              this.isSend = false;
              emailjs.sendForm(this.serviceId, this.templateId, this.$refs.form, this.publicKey)
              .then((result) => {
                  this.showAlert('success', 'Email has been successfully');
                  this.name = '';
                  this.email = '';
                  this.message = ''; 
              }, (error) => {
                  this.showAlert('error', result.text);
              });
            }, 3000); 
          } else {
            this.showAlert('error', 'Email not valid');
          } 
        } else {
            this.showAlert('error', 'Data not valid');
        } 
      }
    },
    computed: {
      ...mapGetters({
        getDark: 'getDark',
        company: 'modProfile/getCompany',
      })
    }
  }
</script>