<template>
	<div :class="getDark ? 'bg-zinc-800':'bg-zinc-200'" class="h-screen w-full">
		<div class="pt-[20vh] h-full w-full z-20">
			<div class="flex max-w-sm mx-auto overflow-hidden rounded-lg
			shadow-none sm:shadow-lg lg:max-w-4xl bg-transparent sm:bg-white">
				<!-- left  -->
				<div @click="goTo"  class="hidden cursor-pointer lg:block lg:w-1/2 bg-no-repeat bg-center bg-cover"
					style="background-image:url('/img/loginImg.jpeg')">
				</div>
				<!-- right  -->
				<div class="w-full px-6 py-8 md:px-8 lg:w-1/2">
					<!-- Title  -->
					<div class="w-full flex justify-center">
						<router-link :to="{ name: 'home' }" class="text-5xl cursor-pointer font-semibold">
							<img v-tooltip="{ content: () => 'Back to home', html:true }" class="h-8 w-auto sm:h-10 cursor-pointer" :src="logo" alt="" />
						</router-link>
					</div>
					<div class="flex items-center justify-between mt-4">
						<span class="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
						<div class="text-xs text-center text-secondary uppercase dark:text-gray-400">
							login with email
						</div>
						<span class="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
					</div>
					<!-- Title End  -->
					<!-- Form  -->
					<vee-form @submit="login" :validation-schema="loginSchema">
						<div class="mt-4">
							<global-input v-model="email" label="Email Address" type="email" nama="email"
								class="outline-none focus:border focus:border-zinc-400 bg-zinc-200"></global-input>
						</div>

						<div class="mt-4">
							<global-input v-model="password" label="Password" type="password" nama="password"
								class="outline-none focus:border focus:border-zinc-400 bg-zinc-200"></global-input>
						</div>
						<!-- <a href="javascript:void(0)" @click.prevent="otp" class="text-xs text-gray-500 dark:text-gray-300 hover:underline">OTP</a> -->
						<div class="mt-8">
							<button class="w-full px-4 py-2 tracking-wide text-white transition-colors
							duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary"
								v-if="loadingSpinner">
								<global-loading></global-loading>
							</button>
							<button v-else class="w-full px-4 py-2 tracking-wide text-white transition-colors
								duration-200 transform bg-gray-700 rounded hover:bg-primary
								focus:outline-none focus:bg-primary hover:cursor-pointer">
								Login
							</button>
						</div>
					</vee-form>
					<!-- Form End  -->
					<div class="flex items-center justify-between mt-4">
						<span class="w-1/5 border-b dark:border-primary md:w-1/4"></span>
						<div @click="signup"
							class="text-xs text-gray-500 uppercase dark:text-gray-400 hover:cursor-pointer">
							Daftar
						</div> | <div href="javascript:void(0)" @click="resetP"
							class="text-xs text-gray-500 uppercase dark:text-gray-400 hover:no-underline cursor-pointer">
							Lupa Password</div>
						<span class="w-1/5 border-b dark:border-primary md:w-1/4"></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import GlobalInput from '@/components/GlobalInput.vue';
import GlobalLoading from '@/components/GlobalLoading.vue';
import Alert from '@/mixin';

export default {
	name: 'Login',
	components: {
		GlobalInput,
		GlobalLoading,
	},
	mixins: [Alert],
	data() {
		return {
			loginSchema: {
				email: 'required|email',
				password: 'required',
			},
			loadingSpinner: false,
			email: '',
			password: '',
			titleApp: process.env.VUE_APP_NAMES,
			warning: {
				message: 'Login Invalid, please try again',
				color: 'bg-red-300',
			},
		};
	},
	methods: {
		goTo() {
			this.$router.push({ name: 'home' });
		},
		signup() {
			this.$router.push({ name: 'signUp' });
		},
		resetP() {
			this.$router.push({ name: 'form-reset-password' });
		},
		otp() {
			this.$router.push({ name: 'otp' });
		},
		getAvatarName(name) {
			const words = name.split(' ');
			for (let i = 0; i < words.length; i++) {
				words[i] = words[i][0].toUpperCase() + words[i].substr(1);
			};
			const nama = words.join(' ');
			return nama;
		},
		async login() {
			this.loadingSpinner = true;
			const data = {
				'unique': this.email,
				'password': this.password,
			};
			const config = {
				headers: {
					'Content-type': 'application/json',
				},
				withCredentials: true,
			};
			try {
				const res = await axios.post(`${process.env.VUE_APP_LOCAL}/v1/login`, data, config)
					.then(res => {
						const payload = res.data;
						if (payload.result.token_access['x-token']) {
							const avatar = `https://ui-avatars.com/api/?name=${this.getAvatarName(payload.result.fullname)}`;
							const profile = {
								'email': payload.result.email,
								'fullname': payload.result.fullname,
								'id': payload.result.id,
								'phone': payload.result.phone,
							};
							const arrLogin = {
								'AUTH_TOKEN': payload.result.token_access['x-token'],
								'profile': profile,
								'avatar': avatar,
							};

							this.showAlert('success', 'Login Successfully');
							setTimeout(() => {
								this.loadingSpinner = false;
								this.$store.dispatch('setAuthenticate', JSON.stringify(arrLogin));
								this.$store.dispatch('modTransaksi/setAllTransaction');
								this.$store.dispatch('modCart/setCart');
								this.$router.push({ name: 'home' });
							}, 2000);

						} else {
							this.loadingSpinner = false;
							this.showAlert('error', 'Invalid username or password');
						};
					})
					.catch(err => {
						this.loadingSpinner = false;
						this.showAlert('error', 'Invalid username or password');
					});
			} catch (error) {
				this.loadingSpinner = false;
				this.showAlert('error', 'Network Error');
			};
		},
	},
	computed: {
		...mapGetters({
			getDark: 'getDark',
			logo: "logo"
		}),
	},
};
</script>

<style scoped>
.slide-fade-enter-from {
	opacity: 0;
}

.slide-fade-enter-active {
	transition: all 1s ease 0.3s;
}

.slide-fade-enter-to {
	opacity: 1;
}

/* .slide-fade-leave-from {
		opacity: 1;
	} */
.slide-fade-leave-active {
	transition: opacity 1s;
}

.slide-fade-leave-to {
	opacity: 0;
}
</style>