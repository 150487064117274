<template>
    <div :class="getDark ? 'bg-zinc-800':'bg-zinc-200'" class="h-screen w-full">
        <div class="pt-[10vh] h-full w-full z-20">
            <div class="flex max-w-sm mx-auto overflow-hidden rounded-lg
			shadow-none sm:shadow-lg lg:max-w-4xl bg-transparent sm:bg-white">

                <div @click="goTo" v-tooltip="{ content: () => 'Back to home', html:true }"
                    class="hidden cursor-pointer lg:block lg:w-1/2 bg-no-repeat bg-center bg-cover"
                    style="background-image:url('/img/loginImg.jpeg')">
                </div>

                <div class="w-full px-6 py-8 md:px-8 lg:w-1/2">
                    <div class="w-full flex justify-center">
                        <router-link :to="{ name: 'home' }" class="text-5xl cursor-pointer font-semibold">
                            <img class="h-8 w-auto sm:h-10 cursor-pointer"
                                :src="logo" alt="" />
                        </router-link>
                    </div>
                    <div class="flex items-center justify-between mt-4">
                        <span class="w-1/5 border-b dark:border-primary lg:w-1/4"></span>
                        <a href="javascript:void(0)"
                            class="text-xs text-center text-secondary uppercase dark:text-gray-400 ">register</a>
                        <span class="w-1/5 border-b dark:border-gray-400 lg:w-1/4"></span>
                    </div>

                    <vee-form :validation-schema="schema" @submit="registrasi">
                        <div class="mt-4">
                            <global-input v-model="fullname" type="text"
                                class="outline-none focus:border focus:border-zinc-400 bg-zinc-200" nama="fullname"
                                label="Full Name">
                            </global-input>
                        </div>
                        <div class="mt-4">
                            <global-input v-model="email" type="email"
                                class="outline-none focus:border focus:border-zinc-400 bg-zinc-200" nama="email"
                                label="Email Address">
                            </global-input>
                        </div>
                        <div class="mt-4">
                            <global-input v-model="telepon" type="number"
                                class="outline-none focus:border focus:border-zinc-400 bg-zinc-200" nama="telepon"
                                label="No Telp"></global-input>
                        </div>

                        <div class="mt-4">
                            <global-input v-model="password" type="password"
                                class="outline-none focus:border focus:border-zinc-400 bg-zinc-200" nama="password"
                                label="Password">
                            </global-input>
                        </div>

                        <div class="mt-8">
                            <button
                                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary"
                                v-if="loadingSpinner">
                                <global-loading></global-loading>
                            </button>
                            <button
                                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded hover:bg-primary focus:outline-none focus:bg-primary"
                                @click="register" v-else>
                                <div class="flex items-center justify-center text-white ease-in-out duration-300">
                                    <span class="font-bold">Register</span>
                                </div>
                            </button>
                        </div>
                    </vee-form>

                    <div class="flex items-center justify-between mt-4">
                        <span class="w-1/5 border-b dark:border-primary md:w-1/4"></span>

                        <a href="javascript:void(0)" @click="login"
                            class="text-xs text-gray-500 uppercase dark:text-gray-400 hover:underline">login</a> |

                        <a href="javascript:void(0)" @click="resetP"
                            class="text-xs text-gray-500 uppercase dark:text-gray-400 hover:underline">Lupa
                            Password</a>

                        <span class="w-1/5 border-b dark:border-primary md:w-1/4"></span>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
import axios from 'axios';
import GlobalInput from '@/components/GlobalInput.vue';
import GlobalLoading from '@/components/GlobalLoading.vue';
import Alert from '@/mixin';
import { mapGetters } from 'vuex';

export default {
    name: 'SignUp',
    components: {
        GlobalInput,
        GlobalLoading,
    },
    mixins: [Alert],
    data() {
        return {
            schema: {
                fullname: 'required|min:3|max:50|alpha_spaces',
                telepon: 'required|min:5|integer',
                email: 'required|min:5|email',
                password: 'required|min:6|max:100',
            },
            titleApp: process.env.VUE_APP_NAMES,
            show: false,
            loadingSpinner: false,
            errMessage: 'Register Invalid',
            fullname: 'bayu',
            email: '',
            telepon: '081384133713',
            password: 'admin123',
        };
    },
    methods: {
        goTo() {
            this.$router.push({ name: 'home' });
        },
        login() {
            this.$router.push({ name: 'login' });
        },
        resetP() {
            this.$router.push({ name: 'form-reset-password' });
        },
        getAvatarName(name) {
            const words = name.split(' ');
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            };
            const nama = words.join(' ');
            return nama;
        },
        async registrasi() {
            this.loadingSpinner = true;
            localStorage.removeItem('dataPreRegister');
            localStorage.removeItem('dataRegister');
            // if(this.telepon != '' && this.fullname != '' && this.email != '' && this.password != ''){ 
            var dataPreRegister = {
                'email': this.email,
                'phone': this.telepon
            };
            const dataRegister = {
                'fullname': this.fullname,
                'phone': this.telepon,
                'email': this.email,
                'password': this.password
            };
            const daPre = JSON.stringify(dataPreRegister);
            const daReg = JSON.stringify(dataRegister);

            localStorage.setItem('dataPreRegister', daPre);
            localStorage.setItem('dataRegister', daReg);
            try {
                await axios.post(`${process.env.VUE_APP_LOCAL}/v1/pre/register`, daPre)
                    .then(res => {
                        if (res.status == 200) {
                            //    console.log(res.data.result);

                            // this.errMessage = res.data.message;
                            // this.errMessage = 'Login Successfully';
                            // setTimeout(() => {
                            //     this.$store.dispatch('setShowAlert', {
                            //         message: this.errMessage,
                            //         color: 'bg-green-300',
                            //     });
                            // }, 1000);
                            if (res.data.result) {
                                setTimeout(() => {
                                    this.$router.push({ name: 'otp' })
                                    this.loadingSpinner = false;
                                }, 4000);
                            }
                        } else {
                            this.errMessage = 'Network Error';
                            setTimeout(() => {
                                this.showAlert('error', this.errMessage);
                                this.loadingSpinner = false;
                            }, 2000);
                        };
                    })
                    .catch((err) => {
                        this.errMessage = "Email or Phone existing used";
                        setTimeout(() => {
                            this.showAlert('error', this.errMessage);
                            this.loadingSpinner = false;
                        }, 2000);
                    });
            } catch (error) {
                this.errMessage = 'Register Failed';
                setTimeout(() => {
                    this.showAlert('error', this.errMessage);
                    this.loadingSpinner = false;
                }, 2000);
            };

            // try {
            //     await axios.post(`${process.env.VUE_APP_LOCAL}/v1/register`, daReg)
            //         .then(res => {
            //             if (res.status == 200) {
            //                 //    console.log(res.data);
            //                 this.errMessage = res.data.message;
            //                 this.errMessage = 'Login Successfully';
            //                 setTimeout(() => {
            //                     this.$store.dispatch('setShowAlert', {
            //                         message: this.errMessage,
            //                         color: 'bg-green-300',
            //                     });
            //                 }, 1000);
            //                 setTimeout(() => {
            //                     this.loadingSpinner = false;
            //                     this.$store.dispatch('setShowAlert', {
            //                         message: this.errMessage,
            //                         color: 'bg-green-300',
            //                     });
            //                 }, 2000);
            //                 this.$router.push({ name: 'login' })
            //             } else {
            //                 this.errMessage = 'Network Error';
            //                 setTimeout(() => {
            //                     this.showAlert('error', this.errMessage);
            //                     this.loadingSpinner = false;
            //                 }, 2000);
            //             };
            //         })
            //         .catch((err) => {
            //             this.errMessage = "Email or Phone existing used";
            //             setTimeout(() => {
            //                 this.showAlert('error', this.errMessage);
            //                 this.loadingSpinner = false;
            //             }, 2000);
            //         });
            // } catch (error) {
            //     this.errMessage = 'Register Failed';
            //     setTimeout(() => {
            //         this.showAlert('error', this.errMessage);
            //         this.loadingSpinner = false;
            //     }, 2000);
            // }; 
        },
    },
    computed: {
        ...mapGetters({
            getDark: 'getDark',
			logo: "logo"
        }),
    },
};
</script>

<style scoped>
.slide-fade-enter-from {
    opacity: 0;
}

.slide-fade-enter-active {
    transition: all 1s ease 0.3s;
}

.slide-fade-enter-to {
    opacity: 1;
}

.slide-fade-leave-active {
    transition: opacity 1s;
}

.slide-fade-leave-to {
    opacity: 0;
}
</style>