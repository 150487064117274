<template>
    <div class="my-10 lg:container mx-auto lg:w-10/12 px-5">
        <div class="2xl:px-0 flex justify-center items-center 2xl:mx-auto 2xl:container">
            <div class="flex flex-col justify-start items-start w-full space-y-9">
                <div class="flex justify-start flex-col items-start space-y-2 w-full">
                    <button @click.prevent="goBack"
                        :class="getDark ? 'text-zinc-500':'text-gray-600 hover:text-gray-500'"
                        class="flex flex-row items-center space-x-1">
                        <i class="fa-solid fa-chevron-left px-2 text-sm -pt-2"></i>
                        <p class="text-sm leading-none">Back</p>
                    </button>
                    <div class="w-full">
                        <div :class="getDark ? 'text-zinc-400':'text-zinc-800'" class="flex justify-between">
                            <div>
                                <h1 class="font-semibold text-2xl">Shopping Cart</h1>
                            </div>
                            <div>
                                <h2 class="font-semibold text-2xl">{{ getCart.length }} Items</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col xl:flex-row justify-center xl:justify-between
                    space-y-6 xl:space-y-0 xl:space-x-6 w-full">

                    <div :class="getDark ? 'text-zinc-400 bg-zinc-800 border border-zinc-700':'text-zinc-800'" class="flex flex-col justify-start items-center
                        py-2 sm:py-0 md:py-10 px-0 md:px-10 xl:w-full">
                        <div class="w-full" v-if="getCart.length > 0">
                            <div :class="getDark ? 'border-zinc-700':'bg-gray-50'"
                                class="grid grid-cols-12 gap-4 items-center px-4 py-2 hover:shadow relative mb-4 pb-4"
                                v-for="(item, i) in getCart" :key="i">
                                <div class="col-span-12 lg:col-span-10 w-full">

                                    <div class="grid grid-cols-12 gap-4">
                                        <div class="col-span-12 md:col-span-4 xl:col-span-2 w-full">
                                            <label :for="`pay${item.id}`" class="cursor-pointer">
                                                <div class="absolute top-3 left-5">
                                                    <input type="checkbox" :id="`pay${item.id}`"
                                                        :checked="dataCheckout.includes(item.id)" class="w-6 h-6"
                                                        @change="onCheck(item)">
                                                </div>

                                                <!-- default button  -->
                                                <div class="absolute top-10 left-5">
                                                    <button v-if="dataCheckout.includes(item.id)"
                                                        @click.prevent="removeCart(item)" class="font-semibold hover:cursor-pointer px-2 py-1 bg-red-500
                                                        flex-0 text-white text-xs inline-block w-auto">
                                                        <i class="fa-solid fa-trash-can"></i>
                                                    </button>
                                                </div>
                                                <img class="w-full object-cover group-hover:duration-500 group-hover:ease-in
                                                ease-out duration-500 object-center"
                                                    :src="item.image ? item.image : gambar"
                                                    :alt="`${item.product_name}`">
                                            </label>
                                        </div>

                                        <div class="col-span-12 md:col-span-8 xl:col-span-10">
                                            <div class="font-semibold text-md md:text-lg">{{ item.product_name }}</div>
                                            <div v-if="item.id_category === 2" class="text-sm w-full">
                                                <span v-html="limitStr(item.desc, 100)"></span>
                                                <!-- {{ limitStr(item.desc, 100) }} -->
                                            </div>
                                            <div v-else class="text-sm w-full grid grid-cols-12">
                                                <div class="col-span-4">
                                                    <li v-for="(item, i) in item.desc.bank" :key="i">{{item}}</li>
                                                </div>
                                                <div class="col-span-4">
                                                    <li v-for="(item, i) in item.desc.cell" :key="i">{{item}}</li>
                                                </div>
                                            </div>
                                            <!-- button delete  -->
                                            <div v-if="dataCheckout.includes(item.id)">
                                                <!-- loading button  -->
                                                <div class="flex w-2/12 items-center justify-center h-8 
                                                    ease-in-out duration-300" v-if="isLoading && item.id === idActive">
                                                    <svg class="w-5 h-5 text-red-600 animate-spin" fill="none"
                                                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <circle class="opacity-25" cx="12" cy="12" r="10"
                                                            stroke="currentColor" stroke-width="4"></circle>
                                                        <path class="opacity-100"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            fill="currentColor" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 lg:col-span-2 w-full text-left">
                                    <span class="font-bold text-orange-500 text-xl md:text-md">{{
                                    formatRupiah(item.price) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else id="modal"
                            class="container mx-auto flex justify-center items-center px-4 md:px-10 py-20">
                            <!--- more free and premium Tailwind CSS components at https://tailwinduikit.com/ --->
                            <div class="bg-white dark:bg-gray-800 p-3 flex flex-col justify-center items-center w-full">
                                <h1
                                    class="text-3xl lg:text-4xl font-semibold leading-10 text-center text-gray-800 md:w-9/12 lg:w-7/12 dark:text-white">
                                    You don't have any cart</h1>
                                <p
                                    class="text-base leading-normal text-center text-gray-600 md:w-9/12 lg:w-7/12 dark:text-white">
                                    Please add some cart</p>
                                <div class="mt-12 md:mt-14 w-full flex justify-center">
                                    <button @click="goTo"
                                        class="dark:text-white dark:border-white w-full sm:w-auto border border-gray-800 text-base font-medium text-gray-800 py-5 px-14 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-primary hover:text-white dark:hover:text-white dark:hover:bg-gray-700">Back
                                        to Product</button>
                                </div>
                            </div>
                        </div>

                        <Transition>
                            <div v-if="getCart.length > 0" class="w-full my-8 ">
                                <div :class="getDark ? 'border-zinc-200':''"
                                    class="flex font-bold justify-between py-6 text-lg uppercase ">
                                    <span>Total Bayar</span>
                                    <span>{{ formatRupiah(totalBayar) }}</span>
                                </div>
                                <button @click="checkout" class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 rounded-lg text-sm
                                text-white uppercase w-full">Checkout</button>
                            </div>
                        </Transition>

                    </div>
                    <!-- <div class="p-8 bg-gray-100 dark:bg-gray-800 flex flex-col lg:w-full xl:w-3/5">
                        <p class="text-3xl lg:text-4xl font-semibold leading-7 lg:leading-9
                        text-gray-800 dark:text-gray-50 border-b pb-8">Order Summary</p>
                        <div class="flex justify-between mt-10 mb-5">
                            <span class="font-semibold text-sm uppercase">Items {{ getCart.length }}</span>
                            <span class="font-semibold text-sm">590$</span>
                        </div>
                        <div class="py-10">
                            <label for="promo" class="font-semibold inline-block mb-3 text-sm uppercase">Promo Code</label>
                            <input type="text" id="promo" placeholder="Enter your code" class="p-2 text-sm w-full">
                        </div>
                        <button class="bg-red-500 hover:bg-red-600 px-5 py-2 text-sm text-white uppercase">Apply</button>
                        <div class="border-t mt-8">
                            <div class="flex font-semibold justify-between py-6 text-sm uppercase">
                                <span>Total cost</span>
                                <span>{{ formatRupiah(600) }}</span>
                            </div>
                            <button class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm
                            text-white uppercase w-full">Checkout</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { faL } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';
import Mixin from '@/mixin/index';
import axios from 'axios';

export default {
    name: 'CheckOut',
    mixins: [Mixin],
    data() {
        return {
            isLoading: false,
            idActive: '',
            dataCheckout: [],
            totalBayar: 0,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.dispatch('modCart/setCart');
            return;
        })
    },
    methods: {
        goBack() {
            this.$router.back(-1);
        },
        goTo() {
            this.$router.push({ name: 'products' });
        },
        async checkout() {
            const data = {
                id: this.dataCheckout,
            }
            this.loader();
            try {
                const token = localStorage.getItem('authenticate');
                var config = {
                    method: 'post',
                    url: `${process.env.VUE_APP_LOCAL}/v1/checkout`,
                    data: JSON.stringify(data),
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(token).AUTH_TOKEN}`
                    }
                };
                await axios(config)

                    .then((res) => {
                        this.$store.dispatch('modCart/setCart');
                        this.$store.dispatch('modTransaksi/setAllTransaction');

                        const links = res.data.result.invoice_url;
                        this.$store.dispatch('showPayment', links)
                        // window.snap.pay(res.data.result.token, {
                        //     onSuccess: () => {
                        //         this.$router.push({ name: 'order' });
                        //     },
                        //     onPending: () => {
                        //         this.$router.push({ name: 'transaction' });
                        //     },
                        //     onError: () => {
                        //         alert("payment failed!"); console.log(result);
                        //     },
                        //     onClose: () => {
                        this.$router.push({ name: 'transaction' });
                        //     }
                        // });
                    })
                    .catch((e) => console.log(e));
            } catch (error) {
                console.log('terjadi kesalahan server');
            };
            // this.$store.dispatch('modCart/setCart');

        },
        onCheck(val) {
            if (!this.dataCheckout.includes(val.id)) {
                this.dataCheckout.push(val.id);
                this.totalBayar += val.price;
            } else {
                this.dataCheckout.splice(this.dataCheckout.indexOf(val.id), 1);  //deleting
                this.totalBayar -= val.price;
            }
        },
        removeCart(val) {
            this.loader();
            if (this.dataCheckout.includes(val.id)) {
                // this.dataCheckout.push(val.id); 
                this.$store.dispatch('modCart/deleteCart', val.id);
                this.dataCheckout.splice(this.dataCheckout.indexOf(val.id), 1);  //deleting
                this.showAlert('success', 'Item Cart has been deleted');
                this.totalBayar -= val.price;
                if (this.dataCheckout.length === 0) {
                    this.dataCheckout = []
                }


                // this.getCart.map((e) => {
                //     this.onCheck(e)
                //     this.dataCheckout.push(e.id) 
                // })
            };
        },
    },

    watch: {
        // getCart(newVal, oldVal) {
        //     this.getCart.map((e) => {
        //         this.dataCheckout.push(e.id);
        //         this.totalBayar += e.price;
        //         // console.log(e)
        //         // this.onCheck(e)
        //         // this.dataCheckout.push(e.id) 
        //     })
        //     // console.log(newVal, oldVal)
        // }
    },
    mounted() {

        // this.getCart.map((e) => {
        //     this.onCheck(e)
        //     this.dataCheckout.push(e.id) 
        // })
    },
    computed: {
        ...mapGetters({
            // getCart: 'modCart/getCart',
            getDark: 'getDark',
        }),
        getCart() {
            this.totalBayar = 0;
            const data = this.$store.getters['modCart/getCart']

            data.map((e) => {
                this.dataCheckout.push(e.id);
                this.totalBayar += e.price;
                // console.log(e)
                // this.onCheck(e)
                // this.dataCheckout.push(e.id) 
            })

            return data
        }
    },
};
</script>