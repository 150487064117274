<template>
  <div>
    <app-jumbotron></app-jumbotron>
    <app-new></app-new> 
    <product-recommended v-if="getProducts != null" @viewDetailProduct="viewProduct"></product-recommended>
    <product-slider v-if="getProducts != null" @viewDetailProduct="viewProduct"></product-slider>
    <!-- <Faq   v-show="show"/> -->
    <!-- <Category />  -->
    <!-- <app-faq></app-faq> -->
  </div>
</template>

<script> 
  import { mapGetters } from 'vuex';
  // import axios from 'axios';
  import AppJumbotron from './Jumbotron.vue';
  import AppNew from './New.vue';
  import ProductRecommended from './Recommended.vue';
  import ProductSlider from './ProductSlider.vue';
  // import moment from 'moment';
  // import Faq from '../components/Faq.vue';
  // import Category from '../components/Category.vue';
  // import AppFaq from '../components/AppFaq.vue';

  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

  export default {
    name: 'HomeView',
    components: {
      AppNew,
      AppJumbotron,
      ProductRecommended,
      ProductSlider,
      Popover,
      PopoverButton,
      PopoverPanel,
      // Faq,
      // Category,
      // AppFaq,
    },
    data() {
      return {
        show: false,
      };
    },
    computed: {
      ...mapGetters(['getProducts']),
    },
    methods: {
      viewProduct(category, slug) {
        this.$store.dispatch('setDetailProduct', category);
        this.$router.push({ name: 'productDetail', params:{ slug: slug} });
      },
    },
    mounted() {
      // this.$store.dispatch('setProduct');
    },
  };
</script>