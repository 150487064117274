<template>
    <div v-if="isLoading">
        <div :class="getDark ? 'border border-zinc-800 bg-zinc-800 text-zinc-300' : 'text-gray-500'" class="p-4">
            <div :class="getDark ? 'border-gray-600' : 'border-gray-200'"
                class="ml-5 flex justify-center items-center px-4 md:px-10 py-20">
                <div :class="getDark ? 'text-zinc-400' : 'text-gray-400'"
                    class="dark:bg-gray-800 p-3 flex flex-col justify-center items-center w-full">
                    <svg class="w-10 h-10 text-gray-500 animate-spin left-1/2 mr-2" fill="none" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            fill="currentColor" />
                    </svg>
                    <h1 class="text-xl font-semibold leading-10 text-center md:w-9/12 lg:w-7/12">
                        Loading</h1>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div v-for="(item, index) in getCompleted" :key="index"
            :class="getDark ? 'bg-zinc-800 border-zinc-800 text-zinc-500':'bg-zinc-50 border-zinc-200 text-zinc-800'"
            class="shadow-sm hover:shadow-lg duration-500 px-2 sm:px-6 md:px-4 py-2 my-2 border group mt-4">
            <div class="grid grid-cols-12 gap-3 hover:cursor-pointer">
                <!-- Summary Column -->
                <div class="col-span-12 sm:col-end-13 px-3 sm:px-0">
                    <div class="flex justify-between items-center">
                        <span :class="getDark ? 'text-zinc-400' : 'text-gray-600'" class="font-light text-xs">
                            {{ item.created_at }}
                        </span>
                    </div>

                    <div @click="cetakInvoice(item)">
                        <div :class="getDark ? 'text-zinc-300' : 'text-gray-700'"
                            class="sm:text-sm md:text-md lg:text-lg  font-semibold">
                            {{ item.product_name }}
                        </div>

                        <p :class="getDark ? 'text-zinc-500' : 'text-gray-600'" class="text-sm md:text-md">
                            {{ item.category_sub }}
                        </p>
                    </div>

                    <!-- Question Labels -->
                    <div class="grid grid-cols-2 my-auto">
                        <!-- Categories  -->
                        <div class="col-span-12 lg:col-span-8">
                            <span class="text-xl font-bold text-orange-400">{{ formatRupiah(item.price) }}</span>
                        </div>

                        <!-- User -->
                        <div class="col-none mr-2 lg:col-start-9 lg:col-end-12">
                            <div :class="item.is_download ? 'bg-blue-100 text-blue-700' : '' " class="inline-block rounded-full 
                                duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100" @click="download(item.id)">
                                {{ item.is_download ? 'Download' : '' }}
                            </div>

                            <div class="inline-block rounded-full 
                                bg-green-100 text-green-700 duration-300 
                                text-xs font-bold 
                                mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                                opacity-90 hover:opacity-100">

                                {{ item.status }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <Pagination v-if="getCompleted.length > 0" :class="getDark ? 'bg-zinc-800 text-zinc-50' : 'text-zinc-800'"
            :pageClasses="getDark ? 'bg-zinc-800 hover:bg-zinc-600 px-4 py-2 border border-zinc-700':'bg-zinc-50 hover:bg-zinc-300 text-zinc-800 px-4 py-2 border border-zinc-300'"
            :activePageClasses="getDark ? 'bg-blue-800 text-white hover:bg-blue-800 px-4 py-2 border border-blue-800':'bg-blue-800 text-white hover:bg-blue-800 text-zinc-50 px-4 py-2 border border-blue-800'"
            v-model="getCompletedPagination.currentPage" :records="getCompletedPagination.totalItem"
            :per-page="getCompletedPagination.perPage" @paginate="functionName" /> -->


        <vue-awesome-paginate :total-items="getCompletedPagination.totalItem"
            :items-per-page="getCompletedPagination.perPage" :max-pages-shown="getCompletedPagination.perPage"
            paginate-buttons-class="bg-slate-300 font-semibold text-primary py-2 pb-3 text-center leading-3 px-3 rounded-lg"
            active-page-class="bg-slate-800 text-white" back-button-class="back-btn" next-button-class="next-btn"
            :current-page="getCompletedPagination.currentPage" :on-click="functionName" v-if="getCompleted.length > 0"
            :class="getDark ? 'bg-zinc-800 text-zinc-50' : 'text-zinc-800'"
            :pageClasses="getDark ? 'bg-zinc-800 hover:bg-zinc-600 px-4 py-2 border border-zinc-700':'bg-zinc-50 hover:bg-zinc-300 text-zinc-800 px-4 py-2 border border-zinc-300'"
            :activePageClasses="getDark ? 'bg-blue-800 text-white hover:bg-blue-800 px-4 py-2 border border-blue-800':'bg-blue-800 text-white hover:bg-blue-800 text-zinc-50 px-4 py-2 border border-blue-800'" />



        <div v-else>
            <div :class="getDark ? 'border border-zinc-800 bg-zinc-800 text-zinc-300' : 'text-gray-500'" class="p-4">
                <div :class="getDark ? 'border-gray-600' : 'border-gray-200'"
                    class="flex justify-center items-center py-20">
                    <div :class="getDark ? 'text-zinc-400' : 'text-gray-800'"
                        class="dark:bg-gray-800 p-3 flex flex-col justify-center items-center w-full">
                        <h1 class="text-3xl lg:text-4xl font-semibold leading-10 text-center md:w-9/12 lg:w-7/12">
                            Data Empty</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Mixin from '@/mixin/index';

// import Vue3Paginate from "vue3-paginate";
// import Pagination from "v-pagination-3";

export default {
    name: "Order",
    mixins: [Mixin],
    components: {
        // Pagination,
        //   NavButton,
    },
    data() {
        return {
            isLoading: false,
            pagination: {
                totalItem: 0,
                currentPage: 1,
                perPage: 5,
                totPage: 1,
                skip: 0,
            },
        };
    },
    created() {
        this.loadData();

        this.showLoading();
    },
    methods: {
        loadData() {
            const totOrder = localStorage.getItem('totOrder');
            this.pagination.totalItem = parseInt(totOrder);
            this.pagination.totPage = Math.ceil(this.getTotalCompletedTransaction / this.pagination.perPage);

            if (this.getCompleted.length === 0) {
                const params = {
                    limit: this.pagination.perPage,
                    skip: this.pagination.skip,
                    filter_product_name: '',
                    filter_tahun: '',
                    filter_category: '',
                    filter_status: 'Completed',
                    sort: 'created_at DESC',
                };

                this.$store.dispatch('modTransaksi/setAllTransaction', params);
            };
        },
        download(id) {
            this.$swal.fire({
                title: '<strong>Perhatian</strong>',
                icon: 'info',
                html:
                    'Anda hanya memiliki kesempatan 1 kali dalam pengunduhan file ini, <br><br>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonColor: '#034078',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Download Files'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.open(`${process.env.VUE_APP_LOCAL}/v1/download/${id}`, '_blank');

                    this.showLoading();
                    setTimeout(() => {
                        this.loadData();
                        this.$router.push({ name: 'order' });
                    }, 3000);
                }
            })
        },
        functionName(val) {
            this.showLoading();
            window.scroll(0, 0)
            this.pagination.skip = (val - 1) * this.pagination.perPage;
            this.pagination.totPage = Math.ceil(this.getTotalCompletedTransaction / this.pagination.perPage);
            this.pagination.currentPage = val;
            const params = {
                limit: this.pagination.perPage,
                skip: this.pagination.skip,
                filter_product_name: '',
                filter_tahun: '',
                filter_category: '',
                filter_status: 'Completed',
                sort: 'created_at DESC',
            };
            this.$store.dispatch('modTransaksi/setAllTransaction', params);

        },
        cetakInvoice(val) {
            this.$store.dispatch('modTransaksi/setInvoice', val);
            // this.loader();
            this.$router.push({ name: 'invoice', params: { id: val.id } });
        }
    },
    computed: {
        ...mapGetters({
            getCompleted: 'modTransaksi/getCompleted',
            getTotalCompletedTransaction: 'modTransaksi/getTotalCompletedTransaction',
            getCompletedPagination: 'modTransaksi/getCompletedPagination',
            getInvoice: 'modTransaksi/getInvoice',
            getDark: 'getDark',
        })
    }
}
</script>

<style scoped>
.btn {
    height: 40px;
    width: 40px;
    border: none;
    margin-inline: 5px;
    cursor: pointer;
}

.back-btn {
    background-color: red;
}

.next-btn {
    background-color: red;
}

.btn-active {
    background-color: blue;
    color: white;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
}

.paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
}

.paginate-buttons:hover {
    background-color: #d8d8d8;
}

.active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
}

.active-page:hover {
    background-color: #2988c8;
}
</style>